.stats
{
    display: flex;
    justify-content: space-evenly;
}
.item
{
    width: 25%;
    height: 100%;
    padding: 50px 0px;
    text-align: center;
}
.item i
{
    vertical-align: middle;
    font-size: 50px;
    color: #005477;
    margin-bottom: 5%;
}
.item p
{
    color: black;
    font-size: 19px;
    margin: 0;
    padding: 10px;
    font-family: 'Open Sans';
    font-weight: bold;
}
.item p.number
{
    font-size: 40px;
    padding: 0;
    font-weight: bold;
}
.item span
{
    width: 70px;
    background: #005477;
    height: 4px;
    display: block;
    margin: 0 auto;
    margin-bottom: 5%;
}
@media screen and (min-width:320px) and (max-width: 767px)
{
    .stats
    {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .item
    {
        padding: 20px 0;
    }
}