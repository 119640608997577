div.cart > div.cart-header
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
}
div.cart table
{
    margin: 20px;
}
table th, table tr, table td
{
    border-collapse: collapse;
    border: 1px solid lightgray;
}
table td
{
    width: calc(100%/5.5);
}
table img
{
    width: 70%;
}
.fa
{
	font-size: 1.8em;
}
.fa-trash-o:hover
{
	color: red;
	cursor: pointer;
}
@media(max-width:1023px)
{
	div.cart table
	{
		margin: 0px;
	}
    table thead
    {
        display: none;
    }
    table, table tbody, table tr, table td
    {
		display: block;
		width: 100%;
	}
    table tr
    {
		margin-bottom:15px;
	}
	table td
    {
		text-align: right;
		padding-left: 50%;
		position: relative;
	}
	table td::before
    {
		content: attr(data-label);
		position: absolute;
		left:0;
		width: 50%;
		padding-left:15px;
		font-size:15px;
		font-weight: bold;
		text-align: left;
	}
	table img
	{
		width: 30%;
	}
}
@media(max-width:1440px)
{
	table td, table th
	{
		text-align: center;
	}
}