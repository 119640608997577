.about-us
{
    height: 75vh;
    width: 100%;
    background: white;
}
div.about
{
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
div.about img
{
    width: 400px;
    height: 400px;
    border-radius: 50%;
    margin-top: 9%;
}
div.about div.text
{
    width: 550px;
}
div.about div.text h2
{
    font-size: 40px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 15%;
}
div.about div.text h5
{
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 20px;
    width: max-content;
    font-style: italic;
}
div.about div.text p
{
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.2px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
@media screen and (min-width:320px) and (max-width: 767px)
{
    .about-us
    {
        padding: 20px 0px;
    }
    div.about img
    {
        display: none;
    }
    
    div.about div.text
    {
        width: 300px;
    }
    div.about div.text h5
    {
        font-size: 13px;
    }
}
@media screen and (min-width:768px) and (max-width: 1023px)
{
    div.about
    {
        gap: 10px;
        justify-content: center;
    }
    div.about div.text
    {
        width: 350px;
    }
}