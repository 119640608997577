@keyframes placeholderShimmer 
{
    0% 
    {
        background-position: -468px 0;
    }
    100% 
    {
        background-position: 468px 0;
    }
}

.placeholder-card 
{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    padding: 16px;
    margin: 8px;
    width: 200px;
    height: 300px;
    border-radius: 8px;
    background-color: #f9f9f9;
}
.placeholder-image,.placeholder-text,.placeholder-button 
{
    background: #e0e0e0;
    background-image: linear-gradient(
        to right,
        #e0e0e0 0%,
        #f0f0f0 20%,
        #e0e0e0 40%,
        #e0e0e0 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    animation: placeholderShimmer 1.5s linear infinite;
}
.placeholder-image 
{
    width: 100%;
    height: 150px;
    border-radius: 4px;
}
.placeholder-text 
{
    width: 80%;
    height: 20px;
    border-radius: 4px;
    margin: 8px 0;
}
.placeholder-text.title 
{
    width: 60%;
}
.placeholder-text.price 
{
    width: 40%;
}
.placeholder-buttons 
{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.placeholder-button 
{
    width: 45%;
    height: 30px;
    border-radius: 4px;
}
