div.gallery
{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 60px;
    max-width: 1000px;
    margin: 0 auto;
    overflow: hidden;
}
div.gallery h2, div.gallery h4
{
    text-align: center;
}
div.gallery img
{
    width: 100%;
    height: 170px;
    padding: 10px;
    border: 1px solid #eef1f5;
    background: #eef1f5;
    position: relative;
    object-fit: fill;
}
div.gallery img:hover
{
    transform: scale(1.15);
    transition: transform ease 0.5s;
}