div.customer-details
{
    width: 60%;
}
div.order-details
{
    width: 40%;
}
div.order-details img
{
    height: 100%;
    width: 23%;
}