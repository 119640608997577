.paragraph 
{
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    text-align: center;
    margin-top: 10%;
}
.paragraph p 
{
    margin-bottom: 20px;
    text-align: center;
    font-family: Georgia;
    font-size: 18px;

}
.paragraph img
{
    width: 100px;
}