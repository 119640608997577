a, a:visited
{
    text-decoration: none;
    color: inherit;
}
.card-container, .placeholder-container
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 10px;
    margin: 0px 10px;
}
.card-container .item-card
{
    border: 1px solid blue;
    width: 300px;
    height: auto;
    padding: 10px;
}
.card-container .item-card img
{
    width: 100%;
    height: 200px;
}
.card-container .item-card h5
{
    height: 60px;
    font-size: 1.2em;
    padding-top: 10px;
}
.card-container .item-card button
{
    margin-top: 20px;
}