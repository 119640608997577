section#services h1
{
    text-align: center;
    padding-top: 7%;
    margin-bottom: 25px;
    font-weight: 400;
    position: relative;
}
section#services h1::after 
{
    content: '';
    background: #0d6efd;
    width: 100px;
    height: 5px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
}
section#services div.services
{
    display: flex;
    gap: 10px;
}
section#services div.services div.service
{
    text-align: center;
    padding: 25px 20px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    background: transparent;
    transition: transform 0.5s, background 0.5s;
}
section#services div.services div.service:hover
{
    background: #005477;
    color: #fff;
    transform: scale(1.0);
}
section#services div.services div.service:hover i
{
    color: #fff;
}
section#services div.services div.service i
{
    font-size: 40px;
    margin-bottom: 10px;
    color: #0d6efd;
}
section#services div.services div.service h2
{
    font-weight: 600;
    margin-bottom: 8px;
    text-transform: uppercase;
}
section#services div.services div.service p
{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1em;
    padding-top: 20px;
}
@media screen and (min-width:320px) and (max-width: 767px)
{
    section#services
    {
        margin-top: 250px;
    }
    section#services h1
    {
        padding-top: 60%;
    }
    section#services div.services
    {
        flex-wrap: wrap;
    }
}
@media screen and (min-width:768px) and (max-width: 1023px)
{
    section#services
    {
        margin-top: 150px;
    }
    section#services div.services
    {
        flex-wrap: wrap;
    }
}
@media screen and (min-width:1024px) 
{
    section#services div.services div.service
    {
        width: calc(100% /4);
    }
}