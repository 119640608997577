.item
{
    border: 1px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
}
.item img
{
    margin: 15px;
    width: 40%;
    height: max-content;
}
.itemText
{
    width: 250px;
}
.buttons
{
    display: flex;
    justify-content: space-between;
    margin: 15px auto;
}
@media screen and (min-width:320px) and (max-width: 767px) 
{
    .item
    {
        flex-direction: column;
    }
    .item img
    {
        width: 100%;
        margin: 30px;
    }
}